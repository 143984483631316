import * as React from "react"
import {Redirect} from "./src/components/language-helper/redirect";
require('./src/styles/global.css');

export const wrapPageElement = ({ element }) => (
    <>
        <Redirect />
        {element}
    </>
);
