exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-credits-js": () => import("./../../../src/templates/credits.js" /* webpackChunkName: "component---src-templates-credits-js" */),
  "component---src-templates-geo-feature-js": () => import("./../../../src/templates/geo-feature.js" /* webpackChunkName: "component---src-templates-geo-feature-js" */),
  "component---src-templates-imprint-js": () => import("./../../../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-solutions-autocomplete-js": () => import("./../../../src/templates/solutions/autocomplete.js" /* webpackChunkName: "component---src-templates-solutions-autocomplete-js" */),
  "component---src-templates-solutions-cartesius-ql-js": () => import("./../../../src/templates/solutions/cartesius-ql.js" /* webpackChunkName: "component---src-templates-solutions-cartesius-ql-js" */),
  "component---src-templates-solutions-geocoding-js": () => import("./../../../src/templates/solutions/geocoding.js" /* webpackChunkName: "component---src-templates-solutions-geocoding-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-solutions-parser-js": () => import("./../../../src/templates/solutions/parser.js" /* webpackChunkName: "component---src-templates-solutions-parser-js" */),
  "component---src-templates-solutions-validator-js": () => import("./../../../src/templates/solutions/validator.js" /* webpackChunkName: "component---src-templates-solutions-validator-js" */),
  "component---src-templates-toc-js": () => import("./../../../src/templates/toc.js" /* webpackChunkName: "component---src-templates-toc-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/use-case.js" /* webpackChunkName: "component---src-templates-use-case-js" */),
  "component---src-templates-use-cases-js": () => import("./../../../src/templates/use-cases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

