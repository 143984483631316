import { graphql, useStaticQuery } from 'gatsby';

export const useDefaultLanguage = () => {
    const data = useStaticQuery(
        graphql`
            query {
                datoCmsSite {
                    locales
                }
            }
        `
    );

    const {
        datoCmsSite: { locales },
    } = data;

    const [defaultLanguage] = locales;

    return { defaultLanguage };
};
